<template>
  <div>
    <div class="main" v-if="!dialogFormVisible&&!isShowDetail">
    <div class="condition-filtrate-box">
      <div class="left-item">
        <el-input class="select-width id-code-width" size="medium" v-model="queryForm.searchValue"
          placeholder="姓名/电话/身份证号" />

        <el-cascader v-model="queryForm.regOrgCode" placeholder="部门" style="width:300px;" :options="cdList" clearable
            :props="{ checkStrictly: true ,label:'orgName',value:'orgCode'}" :show-all-levels="false" filterable>
        </el-cascader>

        <el-select style="margin:0 10px;" placeholder="状态"  size="medium" v-model="queryForm.treatResult"  clearable>
          <el-option v-for="(item, v) in statusList" :key="v" :label="item.label" :value="item.value">
          </el-option>
        </el-select>

        <el-select
          size="medium"
          v-model="queryForm.treatOrgCode"
          placeholder="隔离点">
            <el-option v-for="item in allIsolationList" :key="item.id" :label="item.orgName" :value="item.orgCode" />
          </el-select>

          <el-select style="margin:0 10px;" placeholder="人群分类"  size="medium" v-model="queryForm.crowdTypeId"  clearable>
            <el-option v-for="item in typepopSelect" :key="item.id" :label="item.ruleName"  :value="item.id"></el-option>
          </el-select>

        <el-select style="margin:0 10px;" placeholder="人员类型"   size="medium" v-model="queryForm.personType"  clearable>
          <el-option v-for="(item, v) in ryList" :key="v" :label="item.label" :value="item.value">
          </el-option>
        </el-select>

        <el-date-picker
          style="margin-left:20px;"
          v-model="pickerTime"
          size="medium"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期" />

      </div>

      <div class="right-item" >
        <el-button type="primary" size="small" @click="queryUserInfo">查询</el-button>
      </div>
    </div>

    <el-table height="100%" :data="tableData" row-key="id" default-expand-all border >
      <!-- <el-table-column type="selection" width="40"></el-table-column> -->
      <el-table-column label="操作" width="160" >
        <template slot-scope="{ row }">
          <el-link type="primary" @click="editTableFun(row,'see')">查看详情</el-link>
          <!-- <el-link type="danger"  @click="editUserStatus2(row)">删除</el-link> -->

        </template>
      </el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column prop="sex" :formatter="sexFilter" label="性别" width="60" />
      <el-table-column prop="cardType" :formatter="zjFilter" label="证件类型" width="100"></el-table-column>
      <el-table-column prop="age" label="年龄"></el-table-column>、
      <el-table-column prop="cardNo" label="证件号码" width="200" ></el-table-column>
      <el-table-column prop="linkPhone" label="联系电话" width="120" ></el-table-column>
      <el-table-column prop="currentAddressDetail" label="现居住地址" width="200"></el-table-column>
      <el-table-column prop="country" label="国籍" ></el-table-column>
      <el-table-column prop="nation" label="民族"></el-table-column>
      <el-table-column prop="treatResult" :formatter="Filter2" label="处理结果"></el-table-column>
      <el-table-column prop="orgLinkPhone" label="负责机构" width="200"></el-table-column>
      <el-table-column prop="transportFlag" :formatter="Filter3" label="是否转运"></el-table-column>
      <el-table-column prop="personStatus" :formatter="Filter4" label="当前状态" width="120"></el-table-column>
      <el-table-column prop="orgName" label="登记机构"></el-table-column>
      <el-table-column prop="regUser" label="登记人"></el-table-column>
      <el-table-column prop="regTime" label="登记时间" width="120"></el-table-column>
      <el-table-column prop="treatTime" label="信息推送时间" width="120"></el-table-column>
      <el-table-column prop="treatUser" label="信息推送人员" width="120"></el-table-column>
    </el-table>

    <div class="content-page">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="pageObj.pageNumber" :page-size="pageObj.pageSize" :total="pageObj.total"
        :page-sizes="[10, 20, 30, 50, 100]" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    </div>
  </div>
  <div v-else-if="!isShowDetail">
    <info :dialogFormVisibleId="dialogFormVisibleId" :dialogFormVisibleType="dialogFormVisibleType" ></info>
  </div>
  <!-- 居民详情 -->
  <div v-if="isShowDetail">
      <residentInformation ref="residentInformationRef" />
    </div>
  </div>
</template>

<script>
  const perTypeListJSON = [ //表单
    {
      value: "1",
      label: "社区人员"
    },
    {
      value: "2",
      label: "司机"
    },
    {
      value: "3",
      label: "隔离酒店人员"
    },
    {
      value: "4",
      label: "疾控中心人"
    },
    {
      value: "5",
      label: "转运调度人员"
    },
  ];

  import residentInformation from "@/views/QuarantineRegistration/residentInformation";
  import {
    streetregList,
  } from "@/api/streetSegregation/index.js";

  import {
    getSysOrgTreeListByOrgCode,
    getroleList,
    userSave,
    userUpdate,
    userUpdateStaus,
    getUserOrgRelationList,
    saveUserOrgRelation, getAllIsolationOrgList,
  } from "../../api/SystemManagement/index.js";
  import {
    mapState
  } from "vuex";
  import { getCrowdTypeList } from "@/api/RuleManagement/crowdAnalysis";
import { getToken } from "@/utils/auth";
  import { importFile, messageBox } from '../../utils/utils';
  import info from "./info";

  export default {
    name: "UserManage",
    components: {
      info,
      residentInformation
    },
    data() {
      return {
         upload: "/ohealth/api/v1/epidemicControl/streetreg/uploadStreetRegExcel",
         //上传文件请求头
          myHeaders: {
        jtoken: getToken(),
        clientType: "ADMIN",
        userType: "USER_DOCTOR",
      }, //上传文件请求头
        userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
        tableData: [], //表单数据
        pageObj: {
          pageNumber: 1,
          pageSize: 20,
          total: 0,
        },
        queryForm: {
          searchValue: "",
          regOrgCode:"",
          treatResult: "",
          treatOrgCode:"",
          crowdTypeId:"",
          personType:"",
          regTimeMax: "",
          regTimeMin: "",
        },
        pickerTime: [],
        statusList: [
          {
            value: "",
            label: "全部 "
          },
          {
            value: "2",
            label: "居家隔离"
          },
          {
            value: "3",
            label: "集中隔离"
          },
          {
            value: "1",
            label: "居家健康监测"
          },
        ],
        cdList: [],
        dialogFormVisible: false,
        dialogFormVisibleType: 'add',
        dialogFormVisibleId:'',

        roleList: [],
        allIsolationList: [],
        perTypeList: perTypeListJSON,
        tableFrom:[],


        typepopSelect:[],

        ryList:[
          {
            value: "0",
            label: "正常 "
          },
          {
            value: "1",
            label: "陪护的儿童"
          },
          {
            value: "2",
            label: "老年人"
          },
          {
            value: "3",
            label: "无自理能力的病患"
          },
          {
            value: "4",
            label: "孕产妇"
          },
        ],

        isShowDetail:false,
      };
    },

    computed: {
      ...mapState({
      }),
    },

    created() {
      this.getUserInfo();
      this.cdListFun();
      this.allIsolationListFun();
      this.getCrowdTypeList();
      // this.getRoleList();
    },

    methods: {
      // 隔离点列表
      async allIsolationListFun() {
        try {
          let { data } = await getAllIsolationOrgList();
          this.allIsolationList = data.data;
        } catch (error) {}
      },
      // 人群分类
      async getCrowdTypeList() {
        const { data } = await getCrowdTypeList();
        if (data.code == "200") {
          this.typepopSelect = data.data;
        }
      },

      dialog2Change(val){
        console.log(val);
        this.dialog2Obj.orgName = val.orgName
        this.dialog2Obj.orgCode = val.orgCode
        console.log(this.dialog2Obj);
      },

      dialog3Change(val){
        console.log(val);
        this.dialog3Obj.orgName = val.orgName
        this.dialog3Obj.orgCode = val.orgCode
        console.log(this.dialog3Obj);
      },

    dialog2Fun(){
      console.log(1111111,this.ids);
      let params = Object.assign({ids:this.ids},this.dialog2Obj);
      batchIsolationByHome(params).then(res => {
          let {
            code,
            data,
            msg
          } = res.data;
          console.log("getRoleList -> res.data", res.data);
          if (code == 200) {
            this.$message({
              message: "操作成功！",
              type: "success",
            });
            this.importfiledialog2 =false;
            this.getUserInfo();
          }
        });
    },

    dialog3Fun(){
      console.log(1111111,this.ids);
      let params = Object.assign({ids:this.ids},this.dialog3Obj);
      batchIsolationByHotel(params).then(res => {
          let {
            code,
            data,
            msg
          } = res.data;
          console.log("getRoleList -> res.data", res.data);
          if (code == 200) {
            this.$message({
              message: "操作成功！",
              type: "success",
            });
            this.importfiledialog3 =false;
            this.getUserInfo();
          }
        });
    },
      //文件上传成功
    handelSuccess(res, file, fileList) {
      if (res.code == 200) {
        this.$message({
          message: "上传成功",
          type: "success",
        });
        // this.tempFileList.push({
        //   name: file.response.data.originalName,
        //   url: file.response.data.imgUrl,
        // });
      }else{
        this.$message({
          message: res.message,
          type: "error",
        });
      }
    },
    //文件上传失败
    handelError(error) {
      console.log(error);
      // if (res.code == 200) {
      //   this.$message({
      //     message: "上传失败",
      //     type: "error",
      //   });
      // }
    },
      sexFilter(row) {
        let sex = row.sex;
        const typeMap = {
          '1': "男",
          '0': "女",
        };
        return typeMap[sex] || "未知";
      },
      zjFilter(row) {
        let cardType = row.cardType;
        const cardTypeList = [ //表单
        {
          value: "1",
          label: "居民身份证"
        },
        {
          value: "2",
          label: "护照"
        },
        {
          value: "3",
          label: "港澳通行证"
        },
        {
          value: "4",
          label: "台胞证"
        },
        {
          value: "5",
          label: "其他"
        },
      ];
        let obj = cardTypeList.find((item)=>{
          return item.value == cardType;
        })
        return obj.label || "";
      },
      Filter4(row) {
        let personStatus = row.personStatus;
        const typeMap = {
          '0': "待转运",
          '1': "转运中",
          '2': "取消转运",
          '3': "待隔离",
          '4': "隔离中",
          '5': "待解除隔离",
          '6': "已解除隔离",
        };
        return typeMap[personStatus] || "";
      },
      Filter3(row) {
        let transportFlag = row.transportFlag;
        const typeMap = {
          '1': "是",
          '0': "否",
        };
        return typeMap[transportFlag] || "";
      },
      Filter2(row) {
        let treatResult = row.treatResult;
        const typeMap = {
          '1': "居家监测",
          '2': "居家隔离",
          '3': "集中隔离",
        };
        return typeMap[treatResult] || "";
      },
      cdListFun() {
        getSysOrgTreeListByOrgCode().then((res) => {
          let {
            code,
            data,
            msg
          } = res.data;
          if (code == 200) {
            this.cdList = data;
            console.log(
              "🚀 ~ file: menuManage.vue ~ line 255 ~ getAllSysOrgTreeList ~ this.cdList",
              this.cdList
            );
          }
        });
      },
      getUserInfo() {
        let params = Object.assign({}, this.queryForm, this.pageObj);
        if (Object.prototype.toString.call(params.regOrgCode) === '[object Array]') {
          params.regOrgCode = params.regOrgCode[params.regOrgCode.length - 1]
        }
        if (this.pickerTime && this.pickerTime.length == 2) {
          params.regTimeMin = this.pickerTime[0] + " 00:00:00";
          params.regTimeMax = this.pickerTime[1] + " 23:59:59";
        }
        streetregList({
          params,
        }).then((res) => {
          let {
            code,
            data,
            msg
          } = res.data;
          if (code == 200) {
            this.tableData = data.list;
            this.pageObj.total = Number(data.totalRow);
          }
        });
      },

      // 角色列表
      getRoleList() {
        getroleList().then(res => {
          let {
            code,
            data,
            msg
          } = res.data;
          console.log("getRoleList -> res.data", res.data);
          if (code == 200) {
            this.roleList = data;
          }
        });
      },

      //查询
      queryUserInfo() {
        this.getUserInfo();
      },
      //新增
      addUserInfo() {
        this.dialogFormVisible = true;
        this.dialogFormVisibleType = 'add';
      },

      //table操作
      //修改
      editTableFun(row, type) {
        this.isShowDetail = true;
        this.$nextTick(() => {
        this.$refs.residentInformationRef.getSurveyPersonInfo(row);
      });
      },

      //修改
      editTableFun2(row, type) {
        console.log(row);
        let params ={userId: row.id};
        this.dialogFormVisible2 = true;
        this.dialogFormVisible2Id = row.id;
        getUserOrgRelationList({
          params
        }).then((res) => {
          let {
            code,
            data,
            msg
          } = res.data;
          if (code == 200) {
            this.tableFrom = data;
          }
        })
      },
      addtableFrom(){
        this.tableFrom.push(Object.assign({},tableFromObj))
      },
      edittableFrom(scope){
        this.tableFrom.splice(scope.$index,1);
      },

      // 编辑用户状态
      editUserStatus(row) {
        this.$confirm(`确定要${row.status=="2"?'启用':'停用'}【${row.name}】吗？`, '操作确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let params = {
              id: row.id,
              status: row.status == "1" ? "2" : "1"
            };
            userUpdateStaus(params).then((res) => {
              let {
                code,
                data,
                msg
              } = res.data;
              if (code == 200) {
                this.$message({
                  type: 'success',
                  message: '修改成功!'
                });
                this.queryUserInfo();
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消修改'
            });
          });
      },

      // 用户锁定状态
      editUserStatus2(row) {
        console.log(11,row);
        this.$confirm(`确定要删除【${row.name}】吗？`, '操作确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let params = {
              id: row.id
            };
            console.log(222,params);
            streetDelete(params).then((res) => {
              let {
                code,
                data,
                msg
              } = res.data;
              if (code == 200) {
                this.$message({
                  type: 'success',
                  message: '操作成功!'
                });
                this.queryUserInfo();
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消操作'
            });
          });
      },

      //表单新增
      dialogSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
              this.submitFormFun();
          } else {
            return false;
          }
        });
      },
      submitFormFun() {
        if (Object.prototype.toString.call(this.formInfo.orgId) === '[object Array]') {
          this.formInfo.orgId = this.formInfo.orgId[this.formInfo.orgId.length - 1]
        }
        let params = Object.assign({}, this.formInfo);
        console.log("🚀 ~ file: mechanismManage.vue ~ line 431 ~ submitFormFun ~ params", params)
        var API = this.dialogFormVisibleType == 'add' ? userSave : userUpdate;
        API(params).then((res) => {
          let {
            code,
            data,
            msg
          } = res.data;
          if (code == 200) {
            this.dialogFormVisible = false;
            this.$message({
              type: 'success',
              message: '保存成功!'
            });
            this.queryUserInfo();
          }
        })
      },
      submitFormFun2() {
        console.log(this.tableFrom);
        let arr = this.tableFrom.map((item)=>{
          if (Object.prototype.toString.call(item.orgId) === '[object Array]') {
            item.orgId = item.orgId[item.orgId.length - 1];
          }
          return item
        })
        
        // //必填验证
        // let flag = arr.every((item)=>{
        //   return (item.orgId && item.peopleType && item.roleId)
        // })
        // if(!flag){
        //   this.$message({
        //     type: 'error',
        //     message: '请将数据填写完整!'
        //   });
        //   return
        // }

        let params = {userId:this.dialogFormVisible2Id,sysUserOrgRelationList:arr};
        console.log("🚀 ~ file: mechanismManage.vue ~ line 451 ~ submitFormFun ~ params", params)
        saveUserOrgRelation(params).then((res) => {
          let {
            code,
            data,
            msg
          } = res.data;
          if (code == 200) {
            this.dialogFormVisible2 = false;
            this.$message({
              type: 'success',
              message: '保存成功!'
            });
            this.queryUserInfo();
          }
        })
      },

    //批量
    auditBatchRelieveIsolate(val) {
      this.multipleSelection = val;
      let ids = this.multipleSelection.map((item) => {
        return item.id
      })
      this.ids = ids;
      console.log(1111111,ids);

      // this.names = names.toString();
    },

    // 导入文件
    importfile() {
      this.importfiledialog = true
    },
    // 确定上传文件
    swddd() {
      this.importfiledialog = false
    },

    exportFormInfo() {
      let params = {
        
      };
      messageBox(() => {
        exportStreetRegTemplate({params}).then(res => {
          importFile(res.data, '模板')
        })
      }, "warning", '是否确认下载模板？')
    },

      //分页变化
      handleSizeChange(val) {
        this.pageObj.pageSize = val;
        this.getUserInfo();
      },
      handleCurrentChange(val) {
        this.pageObj.pageNumber = val;
        this.getUserInfo();
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "../../static/css/condition-filtrate.scss";

  .main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .el-table {
      overflow-y: auto;
    }
  }

  .content-page {
    margin-top: 10px;
    height: 40px;
    display: flex;
  justify-content: flex-end;
  margin: 14px;


  }

  .el-link {
    margin-right: 10px;
  }

</style>

<style scoped>
  .el-table>>>td {
    padding: 6px 0 !important;
  }
</style>